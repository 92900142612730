import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import betsyjustin5 from "../images/betsyjustin5.jpg";
import zola from "../images/zola.jpg";
import amazon from "../images/amazon.jpg";

function RegistryPage() {
  return (
    <Layout>
      <SEO
        title="Registry"
        keywords={[`Betsy and Justin Tannenbaums wedding registries`]}
      />
      <div class="max-w-full">
          <div class="relative">
              <div class="relative lg:flex overflow-hidden">
                  <div class="h-screen lg:h-auto lg:w-8/12 relative flex items-center justify-center">
                      <img 
                        class="absolute h-full w-full object-cover" 
                        src={betsyjustin5}
                        alt="Betsy and Justin" 
                      />
                  </div>
                  <div id="registry" class="relative lg:w-4/12 bg-white h-screen">
                    <div class="font-sans-serif relative py-12 lg:py-24 px-8 lg:px-16 text-black leading-relaxed">
                        <p class="mb-6 text-2xl">
                            REGISTRY
                        </p>
                        <p class="text-gray-900">
                          Your presence at our wedding is the greatest gift of all. 
                          However, for those who have expressed an interest,
                          we have registered online at  
                          <a href="https://www.zola.com/registry/theroyaltannenbaums" target="_blank" rel="noopener noreferrer" class="text-blue-600 underline"> Zola</a> and 
                          <a href="https://www.amazon.com/wedding/share/theroyaltannenbaums" target="_blank" rel="noopener noreferrer" class="text-blue-600 underline"> Amazon</a>.
                        </p>
                        <br></br>
                        <div class="mt-32">
                          <a href="https://www.zola.com/registry/theroyaltannenbaums" target="_blank" rel="noopener noreferrer">
                            <img
                              src={zola}
                              alt="Zola Logo"
                            />
                          </a>
                          <a href="https://www.amazon.com/wedding/share/theroyaltannenbaums" target="_blank" rel="noopener noreferrer">
                            <img
                              src={amazon}
                              alt="Amazon Logo"
                            />
                          </a>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
    </Layout>
  );
}

export default RegistryPage;
